require('es6-promise').polyfill();
require('isomorphic-fetch');

(function () {
    // Page is loaded
    const objects = document.getElementsByClassName('asyncImage');
    Array.from(objects).map((item) => {
        // Start loading image
        const img = new Image();
        img.src = item.dataset.src;
        // Once image is loaded replace the src of the HTML element
        img.onload = () => {
            item.classList.remove('asyncImage');
            return item.nodeName === 'IMG' ?
                item.src = item.dataset.src :
                item.style.backgroundImage = `url(${item.dataset.src})`;
        };
    });

    
    // Tooltips
    var addEvent = function (object, type, callback) {
        if (object == null || typeof (object) == 'undefined') return;
        if (object.addEventListener) {
            object.addEventListener(type, callback, false);
        } else if (object.attachEvent) {
            object.attachEvent("on" + type, callback);
        } else {
            object["on" + type] = callback;
        }
    };

    // Show hide tippy based on windows with
    addEvent(window, "resize", function (event) {
        if (document.body.clientWidth > 900) {
            tippy(document.querySelector('.newsletter'), {
                arrow: true,
                placement: 'left',
                hideOnClick: true
            });
            tippy(document.querySelectorAll('.mySlides'), {
                arrow: true,
                placement: 'top',
                hideOnClick: false
            });
            tippy(document.querySelectorAll('.slideChanger'), {
                arrow: true,
                placement: 'top',
                hideOnClick: false
            });
            tippy(document.querySelectorAll('.orderButton'), {
                arrow: true,
                placement: 'left',
                interactive: true,
                content: '<div class="orderButtonTippyText" >Click</div>',
            });

            tippy(document.querySelectorAll('.videoButton'), {
                arrow: true,
                placement: 'top',
                hideOnClick: true
            });
        }



    });

    if (document.body.clientWidth > 900) {
        tippy(document.querySelector('.newsletter'), {
            arrow: true,
            placement: 'left',
            hideOnClick: false
        });
        tippy(document.querySelectorAll('.mySlides'), {
            arrow: true,
            placement: 'top',
            hideOnClick: false
        });
        tippy(document.querySelectorAll('.slideChanger'), {
            arrow: true,
            placement: 'top',
            hideOnClick: false
        });
        tippy(document.querySelectorAll('.orderButton'), {
            arrow: true,
            placement: 'left',
            interactive: true,
            content: '<div class="orderButtonTippyText" >Click</div>',
        });
        tippy(document.querySelectorAll('.videoButton'), {
            arrow: true,
            placement: 'top',
            hideOnClick: true
        });
    }

    /* ENTER KEY TRIGGERS */
    /*
    if (document.querySelector('.newsletterInput')) {
        // Execute a function when the user releases a key on the keyboard
    document.querySelector('.newsletterInput').addEventListener("keyup", function(event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            document.querySelector('.newsletterSend').click();
        }
    });
    }
    
    if (document.querySelector('.orderEmailInput')) {
        document.querySelector('.orderEmailInput').addEventListener("keyup", function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.querySelector('.orderEmailButton').click();
        }
    });
    }*/
    
    
     // Language detection
     var getFirstBrowserLanguage = function () {
         var nav = window.navigator,
             browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
             i,
             language;

         // support for HTML 5.1 "navigator.languages"
         if (Array.isArray(nav.languages)) {
             for (i = 0; i < nav.languages.length; i++) {
                 language = nav.languages[i];
                 if (language && language.length) {
                     return language;
                 }
             }
         }

         // support for other well known properties in browsers
         for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
             language = nav[browserLanguagePropertyKeys[i]];
             if (language && language.length) {
                 return language;
             }
         }

         return null;
     };

    /*
    if (document.body.clientWidth < 1000 && document.getElementById("buttonplan1") && getFirstBrowserLanguage() === 'nb-NO') {
        document.getElementById("buttonplan1").innerHTML = '75<br>NOK';
        document.getElementById("buttonplan2").innerHTML = '1569<br>NOK';
        document.getElementById("buttonplan3").innerHTML = '4059<br>NOK';
    } else if (document.body.clientWidth < 1000 && document.getElementById("buttonplan1")) {
        document.getElementById("buttonplan1").innerHTML = '$9';
        document.getElementById("buttonplan2").innerHTML = '$189';
        document.getElementById("buttonplan3").innerHTML = '$489';
    } else if (document.getElementById("buttonplan1")) {
        document.getElementById("buttonplan1").innerHTML = 'Evetro Application';
        document.getElementById("buttonplan2").innerHTML = 'Implementation & Facilitation';
        document.getElementById("buttonplan3").innerHTML = 'Implementation & Team strategies';
    }*/


     // Typing effect
     var letterCounter = 0;
     var wordCounter = 0;
     var txt = ["Team", "Company"]; /* The text */
     var speed = 100; /* The speed/duration of the effect in milliseconds */
     var waitTime = 3000; // Time before next word

     function resetTyping() {
        document.getElementById("typingEffect").innerHTML = '';
        typeWriter();
     }

     function typeWriter() {
         if (letterCounter < txt[wordCounter].length) {
             document.getElementById("typingEffect").innerHTML += txt[wordCounter].charAt(letterCounter);
             letterCounter += 1;
             setTimeout(typeWriter, speed);
         } else if (letterCounter >= txt[wordCounter].length && wordCounter < txt.length - 1) {
            letterCounter = 0;
            wordCounter += 1;
            setTimeout(resetTyping, waitTime);
         } else {
             wordCounter = 0;
             letterCounter = 0;
             setTimeout(resetTyping, waitTime);
         }
     };

     if (document.getElementById("typingEffect") && (document.body.clientWidth > 600)) {
         var typeText = function() {
             setTimeout(typeWriter(), speed);
         }
        setTimeout(typeText(), 5000);
     } else if (document.getElementById("top_header")) {       
         document.getElementById("top_header").innerHTML = 'Discover smarter ways to improve';
     }

    if (document.getElementById("slideLeft")) {

        // Slideshow
        var slideIndex = 1;
        showDivs(slideIndex);

        function plusDivs(n) {
            showDivs(slideIndex += n);
        }

        document.getElementById("slideLeft").onclick = function () {
            plusDivs(-1);
        };
        document.getElementById("slideRight").onclick = function () {
            plusDivs(+1);
        };



        function showDivs(n) {
            var i;
            var slides = document.getElementsByClassName("mySlides");
            if (slides.length > 0) {
                if (n > slides.length) {
                    slideIndex = 1
                }
                if (n < 1) {
                    slideIndex = slides.length
                };
                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                slides[slideIndex - 1].style.display = "block";
            }
        }
    }
    
    // Contact button
    var anchors = document.getElementsByClassName('top_menu_contact');
    for (var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        anchor.onclick = function () {
            swal({
                title: "post@evetro.com",
                text: "Please contact us if you have any questions",
                allowOutsideClick: true,
                confirmButtonColor: '#00c6ae',
            });
        }
    }
    
    // Mobile menu button (show/hide)
    document.getElementById("mainMenuIcon").onclick = function () {
        event.stopPropagation();
        if (document.getElementById("mainMenuWrapper").style.display === 'block') {
            document.getElementById("mainMenuWrapper").style.display = 'none';
        } else {
            document.getElementById("mainMenuWrapper").style.display = 'block';
        }
         
    };
    document.onclick = function () {
         document.getElementById("mainMenuWrapper").style.display = 'none';
    };

    if (document.getElementById("scrollArrow")) {
        document.getElementById("scrollArrow").onclick = function () {
            scrollTo(document.getElementById("topBackgroundImage").offsetHeight, 300);
        }
    }

    // Scroll function for animated scroll
    const scrollTo = function (to, duration) {
        var element = document.scrollingElement || document.documentElement,
            start = element.scrollTop,
            change = to - start,
            startDate = +new Date(),
            // t = current time
            // b = start value
            // c = change in value
            // d = duration
            easeInOutQuad = function (t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            },
            animateScroll = function () {
                var currentDate = +new Date();
                var currentTime = currentDate - startDate;
                element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    element.scrollTop = to;
                }
            };
        animateScroll();
    };

    //Video button
    if (document.querySelector('.watch_video')) {
        document.querySelector('.watch_video').onclick = function () {
            document.getElementById("wrapper").style.webkitFilter = "blur(10px)";
            document.querySelector('.videoPlayerMain').style.display = "block";
            document.getElementById("backFader").style.display = 'block';
        };
    }

    
    
    //validate email
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    // Red border round email input
    function validateErrorHandling() {
        if (document.querySelector('.newsletter')) {
            // Get newsletter tippys
            var newsletter = document.querySelector('.newsletter');
            var tip1 = newsletter._tippy;
            var newsletterSend = document.querySelector('.newsletterSend');
            var tip2 = newsletterSend._tippy;

            // disable normal tippy
            if (tip1) {
                tip1.hide();
                tip1.disable();
            }
            
            // show error tippy    
            if (tip2) {
                tip2.show();
            }

            if (newsletterInput.classList) {
                newsletterInput.classList.add('newsletterError');
            } else {
                newsletterInput.className += ' ' + 'newsletterError';
            }
         }
    }

    // Handle JSON responses (promises)
    function getJSONResponse(res) {
        if (res.ok) return res.json();
        throw new Error('Server response was not ok.');
    }
    
    

    // For norwegians, set NOK prices
    
    if (getFirstBrowserLanguage() === 'nb-NO' && document.getElementById("mainPrice")) {
        document.getElementById("mainPrice").innerHTML = "75 NOK"

        document.getElementById("pricingTotal_Package10").innerHTML = "750 NOK"
        document.getElementById("pricingTotal_Package50").innerHTML = "3.500 NOK"
        document.getElementById("pricingTotal_Package100").innerHTML = "6.500 NOK"
        document.getElementById("pricingTotal_Package200").innerHTML = "12.000 NOK"
        document.getElementById("pricingTotal_Package500").innerHTML = "27.500 NOK"

        document.getElementById("pricingPerUser_Package10").innerHTML = "75 NOK / user"
        document.getElementById("pricingPerUser_Package50").innerHTML = "70 NOK / user"
        document.getElementById("pricingPerUser_Package100").innerHTML = "65 NOK / user"
        document.getElementById("pricingPerUser_Package200").innerHTML = "60 NOK / user"
        document.getElementById("pricingPerUser_Package500").innerHTML = "55 NOK / user"
    }

    var email;

    // Get started with freebie
    if (document.getElementById("newsletterSend2")) {
        document.getElementById("newsletterSend2").onclick = function () {
            // Validate email   
            var newsletterInput = document.getElementById("newsletterInput");
            email = newsletterInput.value;
            if (validateEmail(email)) {
                document.getElementById("wrapper").style.webkitFilter = "blur(10px)";
                document.getElementById("freebiePopup").style.display = 'block';
                document.getElementById("backFader").style.display = 'block';
                
                document.getElementById("newsletterInput").classList.remove('newsletterError');
                if(tip2) {
                    tip2.hide();
                    tip1.enable();
                }
            } else {
                validateErrorHandling();
            }
        };
    }
    

    // Sign up for early access
    if (document.getElementById("newsletterSend")) {
        document.getElementById("newsletterSend").onclick = function () {
            // Validate email   
            var newsletterInput = document.getElementById("newsletterInput");
            email = newsletterInput.value;
            if (validateEmail(email)) {
                document.getElementById("newsletterInput").classList.remove('newsletterError');
                if (tip2) {
                    tip2.hide();
                    tip1.enable();
                }

                // Create order
                var httpBody = {
                    email: email,
                    system: system,
                    users: users,
                    free: free,
                    basic: basic,
                    enterprise: enterprise,
                };

                snedOrderOnEmail(httpBody);
            } else {
                validateErrorHandling();
            }
        };
    }
    
    // Hide popup function
    var hidePopup = function() {
        var freebiePopups = document.querySelectorAll('.freebiePopup')
        freebiePopups.forEach(function (popup) {
            popup.style.display = 'none';
        });
        document.getElementById("backFader").style.display = 'none';
        document.getElementById("wrapper").style.webkitFilter = "blur(0px)";

        if (document.querySelector('.videoPlayerMain')) {
            document.querySelector('.videoPlayerMain').style.display = "none";
            document.querySelector('.youtube-video').contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        } 
    }

    // Click handling with the popup
    document.getElementById("backFader").onclick = function () {
        hidePopup();  
    };
    if (document.getElementById("closeButtonMini2")) {
        document.getElementById("closeButtonMini2").onclick = function () {
            hidePopup();
        };
    }


    var snedOrderOnEmail = function (httpBody) {
        // Send data to the backend
        fetch('/pricing', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(httpBody),
            })
            .then(getJSONResponse)
            .then((obj) => {
                if (obj) {
                    swal({
                        title: "Thanks!",
                        text: "You will hear from us soon",
                        type: "success",
                        allowOutsideClick: true,
                        confirmButtonColor: '#00c6ae',
                    });
                } else swal('Failure', obj.msg, 'error').then();
            })
            .catch(err => swal(`Connection to /pricing failed`, err.message, 'error'));
    }

    // Order variables
    var system = false;
    var users = 0;
    var free = false;
    var basic = false;
    var enterprise = false;

    // Order button on the popup
    if (document.getElementById("orderEvetro2")) {
        document.getElementById("orderEvetro2").onclick = function () {
            // Validate Email
            var orderEmailInput = document.getElementById("orderEmailInput");
            email = orderEmailInput.value;
            if (validateEmail(email)) {
                // Remove error stying
                orderEmailInput.classList.remove('newsletterError');

                // Hide popup
                hidePopup();

                // Create order
                var httpBody = {
                    email: email,
                    system: system,
                    users: users,
                    free: free,
                    basic: basic,
                    enterprise: enterprise,
                };

                snedOrderOnEmail(httpBody);
                window.location.href = "https://app.evetro.com/";
            } else {
                // Add error styling
                if (orderEmailInput.classList) {
                    orderEmailInput.classList.add('newsletterError');
                } else {
                    orderEmailInput.className += ' ' + 'newsletterError';
                }
            }

        }
    }

    var orderPopup = function(orderTitle) {
        document.getElementById("yourOrder").innerHTML = orderTitle;
        document.getElementById("freebiePopup2").style.display = 'block';
        document.getElementById("wrapper").style.webkitFilter = "blur(10px)";
        document.getElementById("backFader").style.display = 'block';
    }

    if (document.getElementById("topPricingInfo")) {
        document.getElementById("topPricingInfo").onclick = function () {       
            orderPopup("Evetro Application");
            users = 1;
            system = true;
        }
        document.getElementById("10users").onclick = function () {       
            orderPopup("Evetro Application - 10 users");
            users = 10;
            system = true;
        }
        document.getElementById("50users").onclick = function () {       
            orderPopup("Evetro Application - 50 users");
            users = 50;
            system = true;
        }
        document.getElementById("100users").onclick = function () {       
            orderPopup("Evetro Application - 100 users");
            users = 100;
            system = true;
        }
        document.getElementById("200users").onclick = function () {       
            orderPopup("Evetro Application - 200 users");
            users = 200;
            system = true;
        }
        document.getElementById("500users").onclick = function () {       
            orderPopup("Evetro Application - 500 users");
            users = 500;
            system = true;
        }
        document.getElementById("enterprise").onclick = function () {       
            orderPopup("Evetro Application - Enterprise");
            users = "Enterprise";
            system = true;
        }

    }
    if (document.getElementById("orderButton1") && document.getElementById("pricingButton1")) {
        document.getElementById("orderButton1").onclick = function () {
            window.location.href = "https://app.evetro.com/";
        };
        document.getElementById("pricingButton1").onclick = function () {
          window.location.href = "https://app.evetro.com/";
      };
        document.getElementById("orderButton2").onclick = function () {
            orderPopup("Basic");
            basic = true;
        };
        document.getElementById("pricingButton2").onclick = function () {
          orderPopup("Basic");
          basic = true;
        };
        document.getElementById("orderButton4").onclick = function () {
            orderPopup("Enterprise");
            enterprise = true;
        };
        document.getElementById("pricingButton4").onclick = function () {
          orderPopup("Enterprise");
          enterprise = true;
      }
    }
    
    


    var hidePricingWrappers = function (clickedItem) {
        // Remove active class from other pricing buttons
        var buttonplans = document.getElementsByClassName('buttonplan');
        for (var i = 0; i < buttonplans.length; i++) {
            buttonplans[i].classList.remove('activePricingButton');
        }
        // Add active class to active pricing button
        if (clickedItem.classList) {
            clickedItem.classList.add('activePricingButton');
        } else {
            clickedItem.className += ' ' + 'activePricingButton';
        }

        // Hide content from other pricing plans
        var pricingInformationWrappers = document.getElementsByClassName('pricingInformationWrapper');
        for (var i = 0; i < pricingInformationWrappers.length; i++) {
            pricingInformationWrappers[i].style.display = 'none';
        }
    }

    if (document.getElementById("buttonplan2")) {
        hidePricingWrappers(document.getElementById("buttonplan2"));
        document.getElementById("pricingInformationWrapper2").style.display = 'block';
    }

    // Clicking on the buttons at the bottom that switches between the plans
    if (document.getElementById("buttonplan1")) {
        // Pricing box click
        document.getElementById("buttonplan1").onclick = function () {
            scrollTo(600, 300);
            hidePricingWrappers(document.getElementById("buttonplan1"));
            document.getElementById("pricingInformationWrapper1").style.display = 'block';
        };
        document.getElementById("buttonplan2").onclick = function () {
            scrollTo(600, 300);
            hidePricingWrappers(document.getElementById("buttonplan2"));
            document.getElementById("pricingInformationWrapper2").style.display = 'block';
        };
        document.getElementById("buttonplan4").onclick = function () {
            scrollTo(600, 300);
            hidePricingWrappers(document.getElementById("buttonplan4"));
            document.getElementById("pricingInformationWrapper4").style.display = 'block';
        };

    }

})();
/*
(function() {
    'use strict';
    const webpackHotMiddlewareClient = require('webpack-hot-middleware/client?reload=true');

    webpackHotMiddlewareClient.subscribe(function(payload) {
        if (payload.action === 'reload' || payload.reload === true) {
            window.location.reload();
        }
    });

    module.exports = webpackHotMiddlewareClient;
}());*/

